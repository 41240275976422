import * as R from 'ramda'
import * as React from 'react'
import { createSelector } from 'reselect'
import { useDispatch } from 'react-redux'

const INITIAL_STATE = {
  request: {},
  response: {},
}

const EMPTY_ARRAY = Object.freeze([])
const EMPTY_OBJECT = Object.freeze({})

const REQUEST_UPDATED = 'legendz/http/REQUEST_UPDATED'
const RESPONSE_HEADER_ADDED = 'legendz/http/RESPONSE_HEADER_ADDED'
const RESPONSE_STATUS_UPDATED = 'legendz/http/RESPONSE_STATUS_UPDATED'

const REDIRECTED = 'legendz/http/REDIRECTED'

export function updateRequest(request) {
  const headers = R.reduce(
    (normalizedHeaders, headerName) =>
      R.assoc(
        R.toLower(headerName),
        R.path([headerName], request.headers),
        normalizedHeaders
      ),
    {},
    R.keys(request.headers)
  )

  return {
    type: REQUEST_UPDATED,
    payload: {
      headers,
    },
  }
}

export function updateResponseHeader(name, value) {
  return {
    type: RESPONSE_HEADER_ADDED,
    payload: {
      name: R.toLower(name),
      value,
    },
  }
}

export function updateResponseStatus(status) {
  return {
    type: RESPONSE_STATUS_UPDATED,
    payload: status,
  }
}

export function redirect(location, statusCode = 302) {
  return {
    type: REDIRECTED,
    payload: { location, statusCode },
  }
}

export function reducer(state = INITIAL_STATE, action) {
  // Keep reducer’s state empty on client at all times
  if (process.browser) {
    return INITIAL_STATE
  }

  switch (action.type) {
    case REDIRECTED: {
      return R.reduce(reducer, state, [
        updateResponseStatus(action.payload.statusCode),
        updateResponseHeader('location', action.payload.location),
      ])
    }

    case REQUEST_UPDATED: {
      return R.assocPath(['request'], action.payload, state)
    }

    case RESPONSE_HEADER_ADDED: {
      const headers = R.lensPath(['response', 'headers'])
      return R.over(
        headers,
        R.pipe(R.defaultTo([]), R.append(action.payload)),
        state
      )
    }

    case RESPONSE_STATUS_UPDATED: {
      return R.assocPath(['response', 'statusCode'], action.payload, state)
    }

    default: {
      return state
    }
  }
}

/**
 * Get request state
 * @private
 * @param {Object} state HTTP reducer state
 * @returns {Object} HTTP request state
 */
function getRequest(state) {
  return R.propOr(EMPTY_OBJECT, ['request'], state)
}

/**
 * Get normalized (lowercased) request headers
 * @param {Object} state HTTP reducer state
 * @returns {Object} HTTP request headers
 */
export const getRequestHeaders = createSelector([getRequest], request =>
  R.pathOr(EMPTY_OBJECT, ['headers'], request)
)

/**
 * Get response state
 * @private
 * @param {Object} state HTTP reducer state
 * @returns {Object} HTTP response state
 */
function getResponse(state) {
  return R.pathOr(EMPTY_OBJECT, ['response'], state)
}

/**
 * Get response headers list
 * @param {Object} state HTTP reducer state
 * @returns {Array.<Object>} HTTP response headers list
 */
const getResponseHeadersList = createSelector([getResponse], response =>
  R.pathOr(EMPTY_ARRAY, ['headers'], response)
)

/**
 * List of HTTP response headers that may occur several times
 * @constant
 */
const DUPLICATE_HEADERS_WHITELIST = ['set-cookie']

/**
 * Get deduped normalized (lowercased) response headers
 * @param {Object} state HTTP reducer state
 * @returns {Object} HTTP response headers
 */
export const getResponseHeaders = createSelector(
  [getResponseHeadersList],
  R.reduce((headers, header) => {
    const whitelisted = R.includes(header.name, DUPLICATE_HEADERS_WHITELIST)

    if (whitelisted || R.none(R.propEq('name', header.name), headers)) {
      return R.append(header, headers)
    }

    return headers
  }, [])
)

export const isRedirecting = createSelector(
  [getResponseHeaders],
  R.any(R.propEq('name', 'location'))
)

/**
 * Get response status code
 * @param {Object} state HTTP reducer state
 * @returns {Object} HTTP response status code (defaults to 200)
 */
export const getResponseStatus = createSelector([getResponse], response =>
  R.pathOr(200, ['statusCode'], response)
)

export function useResponseStatus(status) {
  const dispatch = useDispatch()

  // Memoize callback to avoid multiple dispatches for same status
  const commitStatus = React.useCallback(
    R.once(() => {
      dispatch(updateResponseStatus(status))
    }),
    [status]
  )

  commitStatus()
}
