import * as React from 'react'
import PropTypes from 'prop-types'

import * as forms from '@rushplay/forms'
import * as common from '@rushplay/common'
import * as t from '@rushplay/theme'
import * as Herz from '@rushplay/herz'
import styled from '@emotion/styled'

import { useDebouncedState } from './use-debounce-state'
import { usePrev } from './use-prev'

const Wrapper = styled('span', {
  shouldForwardProp: common.noneOf(['visible', 'valid', 'empty']),
})`
  overflow: hidden;
  transition: opacity 0.5s ease-in-out;
  word-break: break-word;
  padding-top: 4px;
  ${props =>
    props.valid &&
    `color: ${t.color('success')(props)};
  `};
  ${props =>
    props.valid &&
    props.empty &&
    `color: ${t.color('gray')(props)};
  `};
  color: ${t.color('danger')};
`

function normalizeMinMax(variables) {
  // Destructure the properties from variables
  const { maximum, minimum, ...rest } = variables

  // Normalize maximum and minimum if they exist
  const normalized = {
    ...rest, // Spread the remaining properties
    maximum: maximum !== undefined ? maximum / 100 : undefined,
    minimum: minimum !== undefined ? minimum / 100 : undefined,
  }

  return normalized
}

export function FieldResponse(props) {
  const field = forms.useField(props.scope, { noRegister: true })
  const prevValue = usePrev(field.value)
  const [showError, setShowError, setShowErrorImmediate] = useDebouncedState(
    1000,
    false
  )

  const translate = Herz.I18n.useTranslate(
    () => [field.errors.map(error => error.translationKey)],
    [field.errors]
  )

  const visited = ![
    forms.FieldStatus.PRISTINE,
    forms.FieldStatus.ABSENT,
  ].includes(field.status)

  React.useEffect(() => {
    if (field.errors?.length === 0) {
      setShowErrorImmediate(false)
    } else if (!field.value && visited) {
      setShowErrorImmediate(true)
    } else {
      if (prevValue !== field.value) {
        setShowErrorImmediate(false)
      }
      setShowError(Boolean(field.value))
    }
  }, [field.value, field.errors, visited, prevValue])

  const empty = field.value === ''
  const valid = field.errors?.length === 0
  const visuallyValid = !visited || valid
  const visible = !visuallyValid || (!valid && visited)

  const topError = field.errors[0] ?? undefined

  if (!showError || !visible || valid) {
    return null
  }

  // This is a temporary solution. Rewrite to send already converted values.
  const normalizeTopErrorVariables = normalizeMinMax(
    topError.translationVariables
  )

  return (
    <Wrapper empty={empty} valid={valid}>
      <common.Box
        display="grid"
        gridGap="4px"
        data-testid={`${field.name}.field-response`}
      >
        {topError && (
          <common.Text fontSize={1} key={topError.translationKey}>
            {translate(topError.translationKey, normalizeTopErrorVariables)}
          </common.Text>
        )}
      </common.Box>
    </Wrapper>
  )
}

FieldResponse.propTypes = {
  scope: PropTypes.string.isRequired,
}

export default FieldResponse
