import PropTypes from 'prop-types'

import css from '@styled-system/css'
import styled from '@emotion/styled'

export const FieldLabel = styled.label`
  ${props =>
    css({
      fontSize: '12px',
      lineHeight: '14px',
      fontFamily: 'Raleway',
      fontWeight: '500',
      pb: '8px',
      color: props.fontColor || 'white',
    })}
  display: block;
`

FieldLabel.propTypes = {
  fontColor: PropTypes.string,
  disabled: PropTypes.bool,
}
