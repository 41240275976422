import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Forms from '@rushplay/forms'
import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import loadable from '@loadable/component'

import { Checkbox } from './checkbox'
import { FieldLabel } from './field-label'
import { getFieldNormalizer } from './get-field-normalizer'
import { getFieldParser } from './get-field-parser'

const FieldResponse = loadable(() => import('./field-response'))

export function CheckboxGroup(props) {
  const [options, setOptions] = React.useState([])
  const field = Forms.useField(props.scope, {
    initialValue: props.initialValue,
    normalize: getFieldNormalizer(props.format, props.normalize),
    parse: getFieldParser(props.format, props.parse),
  })
  const translate = Herz.I18n.useTranslate(
    () => [field.label, ...props.options.map(item => item.label)],
    [field.label, props.options]
  )

  function handleChange(key, value) {
    // checkbox was true
    if (value && R.not(R.includes(key, options))) {
      const nextOptions = R.append(key, options)
      setOptions(nextOptions)
      return field.onChangeValue(nextOptions)
    }
    // checkbox was false
    const nextOptions = R.without(key, options)
    setOptions(nextOptions)
    return field.onChangeValue(nextOptions)
  }

  return (
    <Common.Box display="grid" gridGap={0}>
      <FieldLabel disabled={props.disabled}>
        {translate(field.label)}
      </FieldLabel>
      {props.options.map(item => (
        <Checkbox
          key={item.value}
          disabled={R.includes(item.key, props.disabled)}
          checked={R.includes(item.value, options)}
          value={R.includes(item.value, options)}
          onChange={value => handleChange(item.value, value)}
          label={translate(item.label)}
        />
      ))}
      <FieldResponse scope={props.scope} />
    </Common.Box>
  )
}
CheckboxGroup.propTypes = {
  disabled: PropTypes.array,
  label: PropTypes.string,
  format: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  normalize: PropTypes.func,
  options: PropTypes.array,
  parse: PropTypes.func,
  scope: PropTypes.string,
}
