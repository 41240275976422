import * as React from 'react'

import * as Common from '@rushplay/common'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

const blink = keyframes`
  0%, 100% {
    background-color: #0C132B;
  }
  50% {
    background-color: #212836;
  }
`

const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: ${blink} 1.5s infinite;
  background-color: #212836;

  &:nth-of-type(1) {
    animation-delay: 0s;
    background-color: #0c132b;
  }
  &:nth-of-type(2) {
    animation-delay: 0.5s;
  }
  &:nth-of-type(3) {
    animation-delay: 1s;
  }
`

export function ButtonLoader() {
  return (
    <Common.Box
      display="flex"
      width="100%"
      justifyContent="center"
      alignItems="center"
      py={0}
      style={{
        gap: '5px',
      }}
    >
      <Dot />
      <Dot />
      <Dot />
    </Common.Box>
  )
}
