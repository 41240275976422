/*
Wrapper around `redux-effects-universal-cookie` for evening out API
inconsistencies between server and client parts.
*/
import {
  cookie,
  default as cookieMiddleware,
} from 'redux-effects-universal-cookie'

function normalizeOptions(options) {
  const normalizedOptions = Object.assign({}, options)
  if (process.browser) {
    if (options.maxAge) {
      // component-cookie expect property name to be lowercased
      normalizedOptions.maxage = options.maxAge
      delete normalizedOptions.maxAge
    }
  }
  return normalizedOptions
}

export function get(name) {
  return cookie(name)
}

export function set(name, value, options) {
  return cookie(name, value, normalizeOptions(options))
}

export function remove(name, options = {}) {
  return set(name, null, {
    httpOnly: false,
    maxAge: 0,
    path: '/',
    ...options,
  })
}

export function middleware(cookies) {
  return process.browser ? cookieMiddleware() : cookieMiddleware(cookies)
}
