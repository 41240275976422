import * as React from 'react'
import * as ReactRouter from 'react-router-dom'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import css from '@styled-system/css'
import styled from '@emotion/styled'

const Button = styled('button')`
  ${css({
    width: ['50px', '60px'],
    height: ['50px', '60px'],
    bottom: ['50px', 0],
    margin: [1, 2],
    backgroundColor: '#7918dc',
    color: 'white',
  })}
  position: fixed;
  right: 0;
  border: none;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  z-index: 1000;

  &:hover {
    opacity: 0.9;
  }
`

const SvgIcon = styled('svg')`
  ${css({
    height: ['26px', '30px'],
    width: ['26px', '30px'],
  })}
`

function ChatIcon() {
  return (
    <Common.Box pt="4px">
      <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          fill="currentColor"
          d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM6 9h12v2H6V9zm8 5H6v-2h8v2zm4-6H6V6h12v2z"
        />
      </SvgIcon>
    </Common.Box>
  )
}

export function SupportChatActionButton() {
  const context = Herz.Chat.useContext()
  const [messages, setMessages] = React.useState(null)
  const { authenticated } = Herz.Auth.useSession()
  const location = ReactRouter.useLocation()

  React.useEffect(() => {
    const initialized =
      typeof zE !== 'undefined' && typeof $zopim !== 'undefined'

    if (initialized) {
      /*eslint-disable-next-line no-undef */
      zE('messenger:on', 'unreadMessages', count => {
        setMessages(count)
      })
      if (!authenticated) {
        // disable cookies for unauthenticated players for secutiry reasons
        /*eslint-disable-next-line no-undef */
        zE('messenger:set', 'cookies', isEnabled(false))
      } else {
        /*eslint-disable-next-line no-undef */
        zE('messenger:set', 'cookies', isEnabled(true))
      }
    }
  }, [context, authenticated])

  function handleMessagingWidget() {
    // TODO: move to platform
    // https://developer.zendesk.com/api-reference/widget-messaging/web/core/#open

    /*eslint-disable-next-line no-undef */
    zE('messenger', 'open')
    setMessages(null)
  }

  if (location?.pathname?.includes('/casino/games/') || !authenticated) {
    return null
  }

  return (
    <Button
      authenticated={authenticated}
      onClick={() => handleMessagingWidget()}
    >
      {messages ? (
        <Common.Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="absolute"
          borderRadius="50%"
          width="15px"
          height="15px"
          fontSize="12px"
          color="white"
          background="#e15346"
          top="-3px"
          right="6px"
          style={{
            pointerEvents: 'none',
          }}
        >
          {messages}
        </Common.Box>
      ) : null}

      <ChatIcon />
    </Button>
  )
}
