import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'

import * as Constants from '../constants'
import { message_broadcast } from '../messaging'

const GoogleOAuthContext = React.createContext(null)

// DEFAULT_SCOPE includes: phonenumber, profile, birthdate, gender, address
const DEFAULT_SCOPE =
  'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/user.phonenumbers.read https://www.googleapis.com/auth/user.addresses.read https://www.googleapis.com/auth/user.birthday.read https://www.googleapis.com/auth/user.gender.read'

/**
 *
 * @name GoogleOAuthProvider
 *
 * Provides Google OAuth functionality to its children components.
 *
 * @param {object} props - Props for GoogleOAuthProvider.
 * @param {string} props.clientId - Google OAuth client ID.
 * @param {string} props.redirectUrl - Callback redirect url
 * @param {React.ReactNode} props.children - Child components.
 * @returns {JSX.Element} GoogleOAuthProvider component.
 *
 * @example
 *
 * <GoogleOAuthProvider clientId="your-client-id" redirectUrl="your-redirect-url">
 *  <App />
 * </GoogleOAuthProvider>
 */
export function GoogleOAuthProvider(props) {
  const location = ReactRouter.useLocation()

  React.useEffect(() => {
    if (location.hash) {
      // get token from query string on google redirect
      const hashParams = new URLSearchParams(location.hash.substring(1))
      const accessToken = hashParams.get('access_token')

      if (accessToken) {
        // broadcast token to the main window
        message_broadcast(Constants.GOOGLE_OAUTH_SESSION_TOKEN, accessToken) // send session token to the main window

        window.close()
      }
    }
  }, [location])

  // Default param requred for google oauth initialization
  const params = React.useMemo(
    () => ({
      client_id: props.clientId,
      redirect_uri: props.redirectUrl,
      response_type: 'token',
      scope: DEFAULT_SCOPE,
      include_granted_scopes: 'true',
      state: 'pass-through value',
    }),
    [props.clientId, props.redirectUrl]
  )

  const contextValue = React.useMemo(
    () => ({
      clientId: props.clientId,
      apiUrl: props.apiUrl,
      params,
    }),
    [props.clientId, props.apiUrl, params]
  )

  return (
    <GoogleOAuthContext.Provider value={contextValue}>
      {props.children}
    </GoogleOAuthContext.Provider>
  )
}

GoogleOAuthProvider.propTypes = {
  clientId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  apiUrl: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string.isRequired,
}

/**
 *
 * @name useGoogleOAuth
 *
 * Hook to access Google OAuth context.
 *
 * @returns {object} Context value containing clientId and scriptLoadedSuccessfully.
 * @throws {Error} Throws an error if used outside of GoogleOAuthProvider.
 *
 * @example
 *
 * const { clientId, apiUrl, params } = useGoogleOAuth()
 */
export function useGoogleOAuth() {
  const context = React.useContext(GoogleOAuthContext)
  if (!context) {
    throw new Error(
      'Google OAuth components must be used within GoogleOAuthProvider'
    )
  }
  return context
}
