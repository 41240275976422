import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'

import * as Herz from '@rushplay/herz'

import * as Player from './player'

/**
 * Context to provide Customer.io analytics methods.
 * @type {React.Context}
 */
export const CustomerIoContext = React.createContext(null)

/**
 *  Provides Customer.io analytics methods to child components.
 *
 * @param {*} children
 * @param {*} params
 * @returns
 */
export function CustomerIoProvider({ children }) {
  const { authenticated } = Herz.Auth.useSession()

  const email =
    ReactRedux.useSelector(state => Player.getEmail(state.player)) || ''
  const firstName =
    ReactRedux.useSelector(state => Player.getFirstName(state.player)) || ''
  const lastName =
    ReactRedux.useSelector(state => Player.getLastName(state.player)) || ''
  const username =
    ReactRedux.useSelector(state => Player.getUsername(state.player)) || ''
  const playerId = `legendz:${username}`
  const analytics = typeof window !== 'undefined' ? window.analytics : null // access script from window object
  const location = ReactRouter.useLocation()

  const isScriptReady = Boolean(analytics)
  /**
   * Track page change.
   * @returns {void}
   */

  React.useEffect(() => {
    if (isScriptReady) {
      // track page change
      analytics.page()
    }
  }, [location.pathname, isScriptReady])

  /**
   * Identify authenticated player.
   * @returns {void}
   */
  React.useEffect(() => {
    if (isScriptReady) {
      if (authenticated && email) {
        // identify authenticated player
        analytics.identify(playerId, {
          email: `legendz-${email}`,
          first_name: firstName,
          last_name: lastName,
        })
      }
    }
  }, [isScriptReady, authenticated, email])

  /**
   * Track a page view.
   * @returns {void}
   * @example
   * page();
   *
   */
  const page = React.useCallback(() => {
    if (analytics) {
      analytics.page()
    }
  }, [analytics])

  /**
   * Identify a user.
   * @param {string} id - The user's unique identifier.
   * @param {Object} attributes - Additional user attributes.
   * @returns {void}
   * @example
   * identify('123', { email: '', first_name: '', last_name: '' });
   */
  const identify = React.useCallback(
    (id, attributes) => {
      if (analytics) {
        analytics.identify(id, attributes)
      }
    },
    [analytics]
  )

  /**
   * Track a custom event.
   * @param {string} eventName - The name of the event to track.
   * @param {Object} properties - Additional event properties.
   * @returns {void}
   * @example
   * track('CustomEvent', { property: 'value' });
   */
  const track = React.useCallback(
    (eventName, properties) => {
      if (analytics) {
        analytics.track(eventName, properties)
      }
    },
    [analytics]
  )

  return (
    <CustomerIoContext.Provider
      value={{ page, track, identify, isScriptReady }}
    >
      {children}
    </CustomerIoContext.Provider>
  )
}

CustomerIoProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
