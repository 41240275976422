export const ClientType = {
  BROWSER: 'browser',
  MOBILE_BROWSER: 'mobile-browser',
  UNKNOWN: 'unknown',
}

export const ConditionalGameTiles = [
  {
    id: 'featured',
    bgColor: 'gameTileFeatured',
  },
  {
    id: 'most-popular',
    bgColor: 'gameTilePopular',
  },
  {
    id: 'highest-win',
    bgColor: 'gameTileHighestWin',
  },
  {
    id: 'newest-game',
    bgColor: 'gameTileNewest',
  },
  {
    id: 'newest-game-table',
    bgColor: 'gameTileNewestTable',
  },
  {
    id: 'newest-game-netent',
    bgColor: 'gameTileNewestProvider',
  },
  {
    id: 'newest-game-playngo',
    bgColor: 'gameTileNewestProvider',
  },
  {
    id: 'newest-game-red-tiger',
    bgColor: 'gameTileNewestProvider',
  },
  {
    id: 'newest-game-big-time-gaming',
    bgColor: 'gameTileNewestProvider',
  },
]

export const liveCasinoCategories = [
  'discover',
  'blackjack',
  'roulette',
  'baccarat',
]

export const ConditionalGameTilesTypes = ConditionalGameTiles.map(
  item => item.id
)

export const CookieKeys = {
  AB_USER_ID: 'ab_user_id',
  CLIENT_TYPE: 'client_type',
  COOKIE_CONSENT: 'cookie-consent',
  COUNTRY_CODE: 'country_code',
  DEVICE_PIXEL_RATIO: 'device_pixel_ratio',
  IS_PHONE_VERIFICATION_REQUIRED: 'is_phone_verification_required',
  LANGAUGE: 'language',
  OFFER_CLAIMED: 'offer_claimed',
  RETURNING_PLAYER: 'returning_player',
  REGISTRATION_FORM_DATA: 'registration_form_data',
  SPORTS_SEEN: 'has_seen_sports',
  TOKEN: 'token',
  ZENDESK_CACHED: 'zendesk_cached',
  HORSE_RACING_SEEN: 'has_seen_horse_racing',
  DEVICE_ID: '_hgdid',
  GID: '_gid',
  SPORTSBOOK_TOKEN: 'sb',
}

export const CookieOptions = {
  // Hero Gaming Device ID
  // Used to track visitor’s device
  [CookieKeys.DEVICE_ID]: {
    maxAge: 30 * 24 * 60 * 60,
    path: '/',
    httpOnly: false,
  },
  [CookieKeys.TOKEN]: {
    maxAge: 24 * 60 * 60,
    path: '/',
  },
  [CookieKeys.IS_PHONE_VERIFICATION_REQUIRED]: {
    maxAge: 180 * 24 * 60 * 60,
    httpOnly: false,
    path: '/',
  },
}

export const CurrencyCodes = ['EUR', 'CAD', 'mBTC', 'AUD', 'USD', 'SCC', 'GCC']

export const CURRENCIES = {
  GCC: 'GCC',
  SCC: 'SCC',
}

export const EmailPattern =
  "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-.]+\\.[a-zA-Z0-9]+$"

export const SsnPattern = /^\d{3}-\d{2}-\d{4}$/

export const GOOGLE_OAUTH_SESSION_TOKEN = 'google-oauth-session-token'
export const FACEBOOK_OAUTH_SESSION_TOKEN = 'facebook-oauth-session-token'
export const GOOGLE_OAUTH_ENDPOINT =
  'https://accounts.google.com/o/oauth2/v2/auth'
export const FACEBOOK_OAUTH_ENDPOINT =
  'https://www.facebook.com/v20.0/dialog/oauth'

export const tileSizeLimit = {
  large: 9,
  medium: 10,
  small: 6,
}

export const GamblingLicenseIds = {
  CURACAO: 'curacao',
  MGA: 'mga',
  SGA: 'sga',
  IOM: 'iom',
}

export const GameTileHeight = {
  LARGE: 435,
  MEDIUM: 306,
  SMALL: 187,
  PICKED_GAMES: 215,
}

export const GAME_TILE_MULTIPLIER = 0.55

export const GameTileWidth = {
  LARGE: 320,
  MEDIUM: 210,
  SMALL: 130,
}

export const isDesktop =
  process.browser && matchMedia('(min-width: 800px)').matches

export const noJapaneeseCharactersPattern =
  '^((?![\u3000-\u303F]|[\u3040-\u309F]|[\u30A0-\u30FF]|[\uFF00-\uFFEF]|[\u4E00-\u9FAF]|[\u2605-\u2606]|[\u2190-\u2195]|\u203B).)+$'

export const PasswordPattern = `^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$`

export const ZipCodePattern = '^[0-9-]+$'

export const katakanaAndLatinPattern =
  "^[\u30A0-\u30FF\uFF01-\uFF64a-zA-Z0-9',\\s\\.\\-]+$"

export const TimeoutSteps = {
  Initial: 0,
  Confirmation: 1,
}

export const TransactionStep = {
  Amount: 0,
  Payer: 1,
}

export const RegistrationStep = {
  Credentials: 0,
  Identity: 1,
}

// TODO: import from payer when available
export const TransactionType = {
  AUTH: 'auth',
  PURCHASE: 'purchase',
  REDEEM: 'redeem',
}

export const ConvertedTiers = {
  no_tier: '',
  low_tier: 'specialist',
  medium_tier: 'expert',
  high_tier: 'master',
}

export const ProcessesIds = {
  EMAIL_UNIQUENESS_REQUEST: 'EMAIL_UNIQUENESS_REQUEST',
  PHONENUMBER_UNIQUENESS_REQUEST: 'PHONENUMBER_UNIQUENESS_REQUEST',
  USERNAME_UNIQUENESS_REQUEST: 'USERNAME_UNIQUENESS_REQUEST',
  REGISTRATION: 'REGISTRATION',
  REGISTER_REQUEST: 'REGISTER_REQUEST',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  CLAIM_BETBACK_REQUEST: 'CLAIM_BETBACK_REQUEST',
}

export const ContentMaxWidth = '1025px'

// See also https://github.com/RushPlay/casino-saga/blob/production/core/app/services/subdivision_code.rb
export const USA_STATES = [
  { value: 'AK', label: 'Alaska' },
  { value: 'AL', label: 'Alabama' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DC', label: 'District of Columbia' }, // Technically not a state.
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'IA', label: 'Iowa' },
  // { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MD', label: 'Maryland' },
  { value: 'ME', label: 'Maine' },
  // { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MS', label: 'Mississippi' },
  // { value: 'MT', label: 'Montana' },
  { value: 'NC', label: 'North Carolina' },
  // { value: 'ND', label: 'North Dakota' },
  // { value: 'NE', label: 'Nebraska' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  // { value: 'NV', label: 'Nevada' },
  { value: 'NY', label: 'New York' },
  // { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VA', label: 'Virginia' },
  { value: 'VT', label: 'Vermont' },
  // { value: 'WA', label: 'Washington' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WY', label: 'Wyoming' },
]
export const MONTHS = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
]

export const DAYS = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
  { value: '14', label: '14' },
  { value: '15', label: '15' },
  { value: '16', label: '16' },
  { value: '17', label: '17' },
  { value: '18', label: '18' },
  { value: '19', label: '19' },
  { value: '20', label: '20' },
  { value: '21', label: '21' },
  { value: '22', label: '22' },
  { value: '23', label: '23' },
  { value: '24', label: '24' },
  { value: '25', label: '25' },
  { value: '26', label: '26' },
  { value: '27', label: '27' },
  { value: '28', label: '28' },
  { value: '29', label: '29' },
  { value: '30', label: '30' },
  { value: '31', label: '31' },
]

export const VipLevels = ['pioneer', 'visionary', 'master', 'icon', 'legend']
