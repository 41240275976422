import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'

import { useServerConfiguration } from './server-configuration'

export const currencies = {
  AUD: Common.AUD,
  CAD: Common.CAD,
  EUR: Common.EUR,
  SEK: Common.SEK,
  NZD: Common.NZD,
  USD: Common.USD,
  SCC: Common.SCC,
  GCC: Common.GCC,
}

const AmountRenderer = Common.createAmountComponent({
  currencies: R.values(currencies),
})

export function Amount(props) {
  const configuration = useServerConfiguration()

  return (
    <AmountRenderer
      {...props}
      currency={props.currency || configuration.currency.code}
      locale={props.locale || configuration.locale.code}
    />
  )
}

Amount.propTypes = {
  currency: PropTypes.string,
  locale: PropTypes.string,
}
