import * as ReduxEffects from 'redux-effects'
import * as ReactRedux from 'react-redux'
import * as React from 'react'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import * as Forms from '@rushplay/forms'
import css from '@styled-system/css'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

import * as Constants from './constants'
import * as Cookies from './cookies'
import * as Icons from './icons'
import { Button } from './button'
import { CookieConsentCheckboxGroup } from './cookie-consent-checkbox-group'
import { FieldBuilder } from './field-builder'
import { HtmlContent } from './html-content'
import { SubmitButton } from './submit-button'
import { consentSchema } from './cookie-consent-ui-schema'

const dataSchema = {
  type: 'object',
  properties: {
    consent: {
      type: 'array',
      items: {
        type: 'string',
        enum: ['base', 'ads'],
      },
    },
  },
  required: ['consent'],
}

function Submit() {
  const translate = Herz.I18n.useTranslate()
  return (
    <Common.Box ml="20px" pb={2}>
      <SubmitButton>{translate('cookie.submit')}</SubmitButton>
    </Common.Box>
  )
}

const CloseWrapper = styled.div`
  ${css({
    px: 0,
    marginTop: '-10px',
    color: 'g-text',
    fontSize: 3,
    '&:hover, &:active': {
      color: 'inputHover',
    },
  })}
  display: flex;
  justify-content: flex-end;
`

const popUpAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px)
  }

  to {
    opacity: 1;
    transform: translateY(0)
  }
`

const ConsentContainer = styled.div`
  position: fixed;
  bottom: 20px;
  width: 100%;
  left: 0px;
  right: 0px;
  z-index: 9999;
  opacity: 0;
  transform: translateY(20px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;

  &.animated {
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-name: ${popUpAnimation};
    animation-delay: 0.7s;
  }

  @media (max-width: 750px) {
    bottom: 18%;
    padding-left: 8px;
    padding-right: 8px;
  }
`

const ConsentContent = styled.div`
  max-width: ${Constants.ContentMaxWidth};
  width: 100%;
  min-height: 72px;
  background: radial-gradient(
    89.53% 92.72% at 50% 117.43%,
    #233980 0%,
    #111540 100%
  );
  padding: 16px;
  border-radius: 8px;
`

export function CookieConsentBanner() {
  const [visible, setVisible] = React.useState(false)
  const [isOpen, setIsOpen] = React.useState(false)

  const translate = Herz.I18n.useTranslate()
  const dispatch = ReactRedux.useDispatch()

  React.useEffect(() => {
    dispatch(
      ReduxEffects.bind(
        Cookies.get(Constants.CookieKeys.COOKIE_CONSENT),
        value => {
          setVisible(!value)
        }
      )
    )
  }, [])

  function handleSubmit(_errors, data) {
    // There isn't real user input so we skip error handler
    const cookieConsent = data ? data.consent : ['base', 'ads']
    setVisible(false)
    dispatch(
      Cookies.set('cookie-consent', cookieConsent, {
        // Consent must be given at minimum every year, hence the 1 year cookie age
        maxAge: 365 * 24 * 60 * 60 * 1000,
        httpOnly: false,
        path: '/',
      })
    )
  }

  return (
    <>
      {visible && (
        <ConsentContainer className="animated">
          <ConsentContent>
            {isOpen && (
              <Common.Box
                display="flex"
                color="#FFF"
                mb="1"
                pt="1"
                borderRadius={0}
                flexDirection="column"
              >
                <CloseWrapper onClick={() => setIsOpen(!isOpen)}>
                  <Icons.Clear />
                </CloseWrapper>

                <Forms.Provider
                  schema={dataSchema}
                  name="consent"
                  onSubmit={(errors, data) => handleSubmit(errors, data)}
                >
                  <FieldBuilder
                    uiSchema={consentSchema}
                    initialValues={{
                      '#/properties/consent': ['base', 'ads'],
                    }}
                    components={{
                      multipleSelectField: CookieConsentCheckboxGroup,
                      submit: Submit,
                    }}
                  />
                </Forms.Provider>
              </Common.Box>
            )}
            <Common.Box
              display="flex"
              alignItems={['center', 'flex-start']}
              justifyContent="center"
              flexDirection="column"
            >
              <Common.Box
                width="100%"
                color="#FFF"
                fontFamily="Raleway"
                display="flex"
                flexDirection={['column', 'row']}
                alignItems={['center', 'flex-end']}
                justifyContent="space-between"
                fontSize="12px"
                textAlign={['center', 'start']}
                pt={['16px', '0px']}
                maxWidth={['330px', '100%']}
              >
                <Common.Box display="flex" flexDirection="column">
                  <Common.Box
                    as="h4"
                    fontFamily="Raleway"
                    color="white"
                    fontWeight="800"
                    fontSize="16px"
                    pb={0}
                  >
                    {translate('cookie-disclaimer.header')}
                  </Common.Box>
                  <HtmlContent
                    html={{ __html: translate('cookie-disclaimer') }}
                  />
                </Common.Box>
                <Common.Box
                  pt={['24px', '0px']}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Common.Space pr={0}>
                    <Button
                      padding="8px 16px"
                      variant="primary"
                      fontSize="14px"
                      onClick={handleSubmit}
                    >
                      {translate('cookie.accept')}
                    </Button>
                  </Common.Space>
                  <Button
                    variant="secondary"
                    fontSize="14px"
                    onClick={() => setIsOpen(!isOpen)}
                    padding="8px 16px"
                  >
                    {translate('cookie.settings')}
                  </Button>
                </Common.Box>
              </Common.Box>
            </Common.Box>
          </ConsentContent>
        </ConsentContainer>
      )}
    </>
  )
}
