import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import React from 'react'

import * as CombinedSelectiors from './combined-selectors'
import * as Constants from './constants'
import * as Player from './player'
import { useSafeUpdateQuery } from './use-safe-update-query'

/**
 * A hook to enforce opening of the user's wallet if the appropriate flag
 * (enforceDeposit) is true. The wallet should open only once after the
 * flag is set.
 */
export function useDepositEnforcing() {
  const history = ReactRouter.useHistory()
  const enforceDeposit = ReactRedux.useSelector(state =>
    Player.getEnforceDeposit(state.player)
  )
  const isPhoneVerificationRequired = CombinedSelectiors.useIsPhoneVerificationRequired()
  const dispatch = ReactRedux.useDispatch()

  const safeQuery = useSafeUpdateQuery({
    wallet: Constants.TransactionType.PURCHASE,
    login: null,
  })

  React.useEffect(() => {
    if (enforceDeposit && !isPhoneVerificationRequired) {
      history.push({
        search: safeQuery,
      })
      dispatch(Player.updateEnforceDeposit(false))
    }
  }, [enforceDeposit, isPhoneVerificationRequired])
}
