import * as Api from '@rushplay/api-client'
import * as Websockets from '@rushplay/websockets'

export const INIT_LIVE_STATISTICS = 'statistics/INIT_LIVE_STATISTICS'
export const UPDATE_BIG_WINS = 'statistics/UPDATE_BIG_WINS'

function initLiveStatistics(data) {
  return {
    type: INIT_LIVE_STATISTICS,
    payload: data,
  }
}

export function fetch() {
  return Api.fetchLiveStatisticsV2({
    success: res => {
      return initLiveStatistics(res.value)
    },
    version: 2,
  })
}

export function updateBigWins(data) {
  return {
    type: UPDATE_BIG_WINS,
    payload: data,
  }
}

export function reducer(state = {}, action) {
  switch (action.type) {
    case INIT_LIVE_STATISTICS:
      return {
        ...state,
        newestBigSettles: action.payload.newestBigSettles,
      }

    case Websockets.NEW_BIG_WIN:
    case UPDATE_BIG_WINS: {
      const newestBigSettles = [action.payload, ...state.newestBigSettles]
      if (newestBigSettles.length > 10) {
        newestBigSettles.pop()
      }

      return {
        ...state,
        newestBigSettles,
      }
    }

    default:
      return state
  }
}

export function getNewestBigSettles(state) {
  return state?.newestBigSettles || []
}
