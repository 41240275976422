import * as ReactRedux from 'react-redux'
import * as React from 'react'
import * as ReactRouter from 'react-router-dom'

import * as Common from '@rushplay/common'
import * as Locks from '@rushplay/compliance/locks'
import * as Herz from '@rushplay/herz'
import { lazy as loadable } from '@loadable/component'

import * as Icons from '../icons'
import * as CombinedSelectors from '../combined-selectors'
import { Chat } from '../support-chat'
import { ClipPaths } from '../clip-paths'
import { CookieConsentBanner } from '../cookie-consent-banner'
import { GlobalErrorBoundary } from '../global-error-boundary'
import { GlobalStyles } from '../global-styles'
import { NotificationsContainer } from '../notifications'
import { SeoCanonicals } from '../seo-canonicals'
import { SeoHreflangs } from '../seo-hreflangs'
import { SeoJsonLd } from '../seo-json-ld'
import { SeoMetadata } from '../seo-metadata'
import { SupportChatActionButton } from '../support-chat-action-button'
import { useCountryCodeListener } from '../use-configuration-listener'
import { useCssCustomProperties } from '../use-css-custom-properties'
import { useDepositEnforcing } from '../use-deposit-enforcing'
import { useGoogleDataLayerManager } from '../use-google-data-layer-manager'
import { useOfferClaimedListener } from '../use-offer-claimed-listener'
import { usePageview } from '../use-pageview'
import { usePhoneVerificationEnforcing } from '../use-phone-verification-enforcing'
import { usePlayerListener } from '../use-player-listener'
import { useProfileRedirect } from '../use-profile-redirect'
import { useReturningPlayerListener } from '../use-returning-player-listener'
import { useServerConfiguration } from '../server-configuration'
import { useSessionListener } from '../use-session-listener'
import { useWebSocketListener } from '../use-web-socket-listener'

import { useLocaleRedirect } from './use-locale-redirect'

// import { useRecaptcha } from '../use-recaptcha'

const LocksContainer = loadable(() => import('./locks'))
const Primary = loadable(() => import('./primary'))
const BlockedMarket = loadable(() => import('./blocked-market'))
const PhoneVerificationDrawer = loadable(() =>
  import('../phone-verification-drawer')
)

function useAppView() {
  const { country } = useServerConfiguration()
  const hasLocks = ReactRedux.useSelector(state => Locks.hasLocks(state.locks))
  const isPhoneVerificationRequired = CombinedSelectors.useIsPhoneVerificationRequired()
  const redirected = useLocaleRedirect()

  if (redirected) {
    return null
  }

  if (country.access === 'BLOCKED') {
    return <BlockedMarket />
  }

  if (hasLocks) {
    return <LocksContainer />
  }

  return (
    <>
      {isPhoneVerificationRequired && <PhoneVerificationDrawer />}
      <Primary />
    </>
  )
}

/**
 * Topmost application component. Shall be used for setting up global
 * application capabilities and to render subapplications (main, locks,
 * language picker, etc.) based on necessary conditions.
 */
export function App() {
  useCountryCodeListener()
  useCssCustomProperties()
  useDepositEnforcing()
  usePhoneVerificationEnforcing()
  useProfileRedirect()
  useGoogleDataLayerManager()
  useOfferClaimedListener()
  usePageview()
  usePlayerListener()
  useReturningPlayerListener()
  useSessionListener()
  useWebSocketListener()
  // useRecaptcha()

  const children = useAppView()

  const location = ReactRouter.useLocation()

  const hashParams = new URLSearchParams(location.hash.substring(1))
  const accessToken = hashParams.get('access_token')

  if (accessToken) {
    return (
      <Common.Box
        width="100vw"
        height="100vh"
        position="fixed"
        top="0px"
        left="0px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        backgroundColor="rgb(13, 17, 29)"
      >
        <Icons.Spinner />
      </Common.Box>
    )
  }

  return (
    <GlobalErrorBoundary>
      <React.Fragment>
        {location.pathname === '/' && <SeoJsonLd />}
        <SupportChatActionButton />
        <SeoMetadata />
        <SeoCanonicals />
        <SeoHreflangs />
        <ClipPaths />
        <GlobalStyles />
        <Common.Box
          display="grid"
          minHeight="fill-available"
          gridTemplateColumns={['minmax(0, 1fr)']}
          gridTemplateRows="auto 1fr"
        >
          {children}
        </Common.Box>

        <NotificationsContainer />
        <Chat />
        <CookieConsentBanner />
      </React.Fragment>
    </GlobalErrorBoundary>
  )
}

Herz.I18n.Loader.preload(
  [
    'cookie-disclaimer',
    'cookie.settings',
    'cookie.accept',
    'cookie.submit',
    'consent.consent.base.title',
    'consent.consent.ads.title',
    'consent.consent.base.description',
    'consent.consent.ads.description',
    'cookie-disclaimer.header',
  ],
  App
)
