import * as React from 'react'
import PropTypes from 'prop-types'

import css from '@styled-system/css'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  word-wrap: break-word;

  h1,
  h2,
  h3,
  h4 {
    color: currentColor;
    margin: 0;
    ${css({
      fontFamily: 'head',
    })}
  }

  h1 {
    line-height: 1.26;
    font-weight: bold;
    ${css({
      fontSize: 6,
    })}
  }

  h2 {
    line-height: 1.14;
    font-weight: normal;
    ${css({
      fontSize: 5,
    })}
  }

  h3 {
    line-height: 1.45;
    font-weight: bold;
    ${css({
      fontSize: 4,
    })}
  }

  h4 {
    line-height: 1.14;
    font-weight: bold;
    ${css({
      fontSize: 3,
    })}
  }

  li {
    padding: 4px;
    line-height: 1.5;
    ${css({
      fontSize: 2,
    })}
  }

  ol,
  ul {
    list-style: initial;
    ${css({
      fontFamily: 'body',
      color: 'currentColor',
      pt: 1,
      pl: 1,
    })}
  }

  p {
    line-height: 1.5;
    margin-bottom: 1em;
    margin-top: 1em;
    ${css({
      fontFamily: 'body',
    })}
  }

  a {
    text-decoration: underline;
    transition: color 50ms ease;

    &:hover {
      ${css({
        color: 'secondary',
      })}
    }
  }

  i {
    font-style: italic;
  }

  b,
  strong {
    font-weight: bold;
  }

  details {
    ${css({
      borderColor: 'input',
    })}

    &:not(last-of-type) {
      border-bottom: 1px solid;
    }

    > main {
      ${css({
        px: 1,
      })}
    }

    summary {
      position: relative;
      display: block;
      cursor: pointer;
      outline: none;
      -webkit-tap-highlight-color: transparent;
      background-color: #fff;
      ${css({
        fontSize: [3, 5],
        fontFamily: 'body',
        py: 1,
        pr: 4,
      })}

      &:after {
        top: 0.7em;
        right: 0.3em;
        position: absolute;
        width: 1.5em;
        height: 1.5em;
        content: '';
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="black" d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/><path fill="none" d="M0 0h24v24H0V0z"/></svg>');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        transform: rotate(-180deg);
      }

      &::-webkit-details-marker {
        display: none;
      }
    }

    &[open] > summary:after {
      transform: rotate(0deg);
    }

    table {
      width: 100%;

      td {
        text-align: center;
      }
    }
  }

  mark[data-type='alert'] {
    font-weight: bold;
    background: transparent;
    ${css({
      color: 'textDanger',
    })}
  }
`

export function HtmlContent(props) {
  const html = props.children ? { __html: props.children } : props.html

  return (
    <Wrapper
      className={props.className}
      dangerouslySetInnerHTML={html}
      as={props.as}
    />
  )
}

HtmlContent.defaultProps = {
  as: 'div',
}

HtmlContent.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.string,
  html: PropTypes.shape({ __html: PropTypes.string }),
}

// for @loadable/content
export default HtmlContent
