import * as ReactRedux from 'react-redux'
import * as React from 'react'

import * as Http from '../http-module'
import { redirect } from '../http'
import { useServerConfiguration } from '../server-configuration'

export function useLocaleRedirect() {
  const configuration = useServerConfiguration()
  const dispatch = ReactRedux.useDispatch()
  const basename = Http.useBasename()
  const url = Http.useUrl()

  const redirected = React.useMemo(() => {
    if (configuration.country.access !== 'ALLOWED') {
      return false
    }

    if (basename === '/es-US' || basename === '/en-US') {
      return false
    }

    dispatch(redirect(`/en-US${url}`))

    if (process.browser) {
      window.location.replace(`/en-US${url}`)
    }

    return true
  }, [basename, configuration, dispatch, url])

  return redirected
}
