import * as React from 'react'
import PropTypes from 'prop-types'

import * as forms from '@rushplay/forms'
import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'

import { CheckboxGroup } from './checkbox-group'
import { InputField } from './input-field'
import { SelectField } from './select-field'
import { SubmitButton } from './submit-button'

function NumberField(props) {
  return <InputField inputMode="numeric" {...props} />
}

function Text(props) {
  const translate = Herz.I18n.useTranslate(() => [props.children], [
    props.children,
  ])
  return <Common.Text {...props}>{translate(props.children)}</Common.Text>
}

export function FieldBuilder(props) {
  return (
    <forms.FieldBuilder
      components={{
        box: Common.Box,
        numberField: NumberField,
        submit: SubmitButton,
        text: Text,
        textField: InputField,
        singleSelectField: SelectField,
        multipleSelectField: CheckboxGroup,
        ...props.components,
      }}
      disabled={props.disabled}
      getComponent={props.getComponent}
      getCustomField={props.getCustomField}
      mountPoint="forms"
      name={props.name}
      initialValues={props.initialValues}
      uiSchema={props.uiSchema}
    />
  )
}

Text.propTypes = {
  children: PropTypes.node,
}

FieldBuilder.propTypes = {
  components: PropTypes.object,
  disabled: PropTypes.bool,
  getComponent: PropTypes.func,
  getCustomField: PropTypes.func,
  initialValues: PropTypes.object,
  name: PropTypes.string,
  uiSchema: PropTypes.object.isRequired,
}
