import * as React from 'react'
import PropTypes from 'prop-types'
import { AnimatePresence, motion } from 'framer-motion'

import * as Common from '@rushplay/common'
import * as Notifications from '@rushplay/notifications'
import * as Herz from '@rushplay/herz'

import { HtmlContent } from './html-content'
import { Toast } from './toast'

function NotificationContent(props) {
  const { notification, notificationId } = props
  const translate = Herz.I18n.useTranslate(
    () => [`toast.${notification.level}`, notification.message],
    [notification]
  )

  return (
    <Common.Space pt={1} px={1}>
      <Toast
        variant={notification.level}
        title={
          notification.level
            ? translate(`toast.${notification.level}`)
            : 'Title'
        }
        onDismiss={() => notification.onDismiss(notificationId)}
      >
        <HtmlContent
          html={{
            __html: notification.contentTranslated
              ? notification.message
              : notification.message
              ? translate(notification.message, notification.variables)
              : 'Content',
          }}
        />
      </Toast>
    </Common.Space>
  )
}

NotificationContent.propTypes = {
  notification: PropTypes.any.isRequired,
  notificationId: PropTypes.any.isRequired,
}

export function NotificationsContainer() {
  return (
    <Common.Box
      position="fixed"
      top={['48px', '0px']}
      right="0px"
      width={['100%', 'auto']}
      minWidth={[null, '350px']}
      maxWidth="420px"
      zIndex="999999999999999"
    >
      <Notifications.Notifications mountPoint="notifications">
        {notificationIds => (
          <AnimatePresence>
            {notificationIds.map(notificationId => (
              <motion.div
                positionTransition
                key={notificationId}
                initial={{ x: 100 }}
                animate={{ x: 0 }}
                exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }}
                transition={{
                  type: 'spring',
                  stiffness: 300,
                  damping: 15,
                  duration: 0.25,
                }}
              >
                <Notifications.Notification
                  id={notificationId}
                  mountPoint="notifications"
                >
                  {notification => (
                    <NotificationContent
                      notification={notification}
                      notificationId={notificationId}
                    />
                  )}
                </Notifications.Notification>
              </motion.div>
            ))}
          </AnimatePresence>
        )}
      </Notifications.Notifications>
    </Common.Box>
  )
}
