import PropTypes from 'prop-types'
import { typography, variant } from 'styled-system'

import * as Common from '@rushplay/common'
import styled from '@emotion/styled'

export const Button = styled('button', {
  shouldForwardProp: Common.noneOf([
    'as',
    'stretch',
    'fontSize',
    'fontFamily',
    'variant',
  ]),
})`
  cursor: pointer;
  outline: none;
  ${props =>
    props.padding ? `padding: ${props.padding}` : 'padding: 0.5em 1.5em;'};
  font-weight: bold;
  line-height: 1.5;
  margin: 0;

  @media (max-width: 750px) {
    padding: 0.5em 1.5em;
  }

  ${props => props.stretch && `width: 100%`};
  // Workaround: theme 'variants' are not read otherwise
  ${variant({
    scale: 'buttons',
    variants: { _: {} },
  })};
  ${typography};
`

Button.displayName = 'Button'

Button.defaultProps = {
  fontFamily: 'head',
  fontSize: [2, 3],
  variant: 'secondary',
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  fontSize: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
  ]),
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'primary-outlined',
    'secondary-outlined',
  ]),
  stretch: PropTypes.bool,
  onClick: PropTypes.func,
}
