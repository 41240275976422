import gql from 'graphql-tag'

export const gameTileFragment = gql`
  fragment GameTileFragment on Game {
    title
    id
    backgroundUrl
    backgroundOverlayImageAlignment
    backgroundOverlayUrl
    titleUrl
    provider
    displayProvider
    blitzEnabled
    loginRequired
    liveCasinoAttributes {
      seatsTotal
      seatsAvailable
      tableOpen
    }
  }
`
