import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'

import * as Constants from '../constants'
import { message_broadcast } from '../messaging'

const FacebookOAuthContext = React.createContext(null)
/**
 *
 * @name FacebookOAuthProvider
 *
 * Provides Facebook OAuth functionality to its children components.
 *
 * @param {object} props - Props for FacebookOAuthProvider.
 * @param {string} props.clientId - Facebook OAuth client ID.
 * @param {string} props.redirectUrl - Callback redirect url
 * @param {React.ReactNode} props.children - Child components.
 * @returns {JSX.Element} FacebookOAuthProvider component.
 *
 * @example
 *
 * <FacebookOAuthProvider clientId="your-client-id" redirectUrl="your-redirect-url">
 *  <App />
 * </FacebookOAuthProvider>
 */
export function FacebookOAuthProvider(props) {
  const location = ReactRouter.useLocation()

  React.useEffect(() => {
    if (location.search) {
      // get token from query string on facebook redirect
      const params = new URLSearchParams(location.search.substring(1))
      const accessToken = params.get('code')

      if (accessToken) {
        // broadcast token to the main window
        message_broadcast(Constants.FACEBOOK_OAUTH_SESSION_TOKEN, accessToken) // send session token to the main window

        window.close()
      }
    }
  }, [location])

  const params = React.useMemo(
    () => ({
      client_id: props.appId,
      redirect_uri: props.redirectUrl,
      scope: 'public_profile,email,user_birthday,user_location',
    }),
    []
  )

  const contextValue = React.useMemo(
    () => ({
      clientId: props.appId,
      apiUrl: props.apiUrl,
      params,
    }),
    [props.appId, props.apiUrl, params]
  )

  return (
    <FacebookOAuthContext.Provider value={contextValue}>
      {props.children}
    </FacebookOAuthContext.Provider>
  )
}

FacebookOAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
  appId: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string.isRequired,
  apiUrl: PropTypes.string.isRequired,
}

/**
 *
 * @name useFacebookOAuth
 *
 * Hook to access Facebook OAuth context.
 *
 * @returns {object} Context value containing clientId and scriptLoadedSuccessfully.
 * @throws {Error} Throws an error if used outside of FacebookOAuthProvider.
 *
 * @example
 *
 * const { clientId, apiUrl, params } = useFacebookOAuth()
 *
 */
export function useFacebookOAuth() {
  const context = React.useContext(FacebookOAuthContext)
  if (!context) {
    throw new Error(
      'Facebook OAuth components must be used within FacebookOAuthProvider'
    )
  }
  return context
}
