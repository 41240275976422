export function calculateTileSize(games, tileSize) {
  if (games.length === 4) {
    return 'custom-small'
  }

  if (games.length < 4) {
    return 'custom-medium'
  }

  return tileSize
}
