import * as React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { Provider as ReduxProvider } from 'react-redux'

import * as common from '@rushplay/common'

import * as ClientConfiguration from './client-configuration'
import * as FetchOptions from './fetch-options'
import * as HerzTranslations from './herz-translations'
import * as DegbugTranslations from './dev-tools/debug-translations'
import * as Cookies from './cookies-module'
import * as Http from './http-module'
import * as Suspense from './suspense'
import * as Theming from './theming'
import PropTypes from './prop-types'
import { ChatProvider } from './support-chat'
import { CustomerIoProvider } from './customer-io-provider'
import { GraphqlProvider } from './graphql-provider'
import { OAuthproviders } from './oauth-providers'
import { Router } from './router'
import { SeonProvider } from './seon-provider'
import { ServerConfigurationProvider } from './server-configuration'

/**
 * Top-level provider composing providers necessary for application runtime
 * @param {Object} props
 */
export function GlobalProvider(props) {
  return (
    <Suspense.Provider>
      <HelmetProvider context={props.context}>
        <ReduxProvider store={props.store}>
          <Http.Provider request={props.request} response={props.response}>
            <Cookies.Provider cookies={props.cookies}>
              <SeonProvider>
                <ClientConfiguration.Provider>
                  <Router context={props.context}>
                    <FetchOptions.Provider>
                      <GraphqlProvider ssrCache={props.ssrCache}>
                        <ServerConfigurationProvider>
                          <OAuthproviders>
                            <Theming.Provider>
                              <common.TimestampProvider>
                                <DegbugTranslations.Provider>
                                  <HerzTranslations.Provider
                                    i18nLoader={props.i18nLoader}
                                  >
                                    <ChatProvider>
                                      <CustomerIoProvider>
                                        {props.children}
                                      </CustomerIoProvider>
                                    </ChatProvider>
                                  </HerzTranslations.Provider>
                                </DegbugTranslations.Provider>
                              </common.TimestampProvider>
                            </Theming.Provider>
                          </OAuthproviders>
                        </ServerConfigurationProvider>
                      </GraphqlProvider>
                    </FetchOptions.Provider>
                  </Router>
                </ClientConfiguration.Provider>
              </SeonProvider>
            </Cookies.Provider>
          </Http.Provider>
        </ReduxProvider>
      </HelmetProvider>
    </Suspense.Provider>
  )
}

GlobalProvider.propTypes = {
  basename: PropTypes.string,
  children: PropTypes.node,
  context: PropTypes.object,
  cookies: PropTypes.object,
  location: PropTypes.string,
  request: PropTypes.object,
  response: PropTypes.object,
  ssrCache: PropTypes.func,
  store: PropTypes.reduxStore.isRequired,
  i18nLoader: PropTypes.object,
}
