/**
 * Transforms the given data into a query string.
 *
 * @param {Object} data - The data to be transformed.
 * @param {Array.<Array.<string>>} data.errors - An array of error pairs, where each pair is a tuple of [field, error message].
 * @param {Object} data.player - An object containing player information.
 * @returns {string} The transformed query string.
 *
 * */

export function transformDataToQueryString(data) {
  if (!data || !data.errors || !data.player) {
    return ''
  }

  // Filter out errors with the message "can't be blank"
  const filteredErrors = data.errors.filter(
    error => error[1] !== "can't be blank"
  )

  // Convert errors array to formatted error strings
  const formattedErrors = filteredErrors.map(error => {
    const [field, message] = error

    let key = field

    if (key.includes('.')) {
      key = key.split('.')[1] // Take the second part after dot
    }

    if (message.includes('invalid') || message.includes('is not a valid')) {
      return `[${key}:invalid]`
    } else if (message.includes('too short')) {
      return `[${key}:short]`
    } else if (message.includes('too long')) {
      return `[${key}:long]`
    } else {
      return `[${key}:${message}]`
    }
  })

  // Convert player object to query string
  const playerQuery = Object.keys(data.player)
    .map(key => {
      return `player[${key}]=${encodeURIComponent(data.player[key])}`
    })
    .join('&')

  // Combine formatted errors and player query strings
  const queryString = [formattedErrors.join('&'), playerQuery]
    .filter(Boolean)
    .join('&')

  return queryString
}

/**
 * Parses a query string into an object containing errors and player data.
 *
 * @param {string} queryString - The query string to parse.
 * @returns {Object} An object containing parsed errors and player data.
 */
export function parseQueryString(search) {
  // Extract query parameters from the search string
  const params = new URLSearchParams(search)

  // Initialize variables to store player data and errors
  const playerData = {}
  const errors = []
  const access_token = params.get('access_token')

  // Function to decode URI component and handle empty values
  const decodeValue = value => {
    return value ? decodeURIComponent(value) : null
  }

  // Extract player data from query parameters
  params.forEach((value, key) => {
    const playerMatch = key.match(/^player\[(\w+)\]$/)
    if (playerMatch) {
      const fieldName = playerMatch[1]

      playerData[fieldName] =
        decodeValue(value) === 'null' ? '' : decodeValue(value)
    }
  })

  // Extract errors from query parameters
  params.forEach((_value, key) => {
    const errorMatch = key.match(/^\[(\w+):(.+)\]$/)
    if (errorMatch) {
      const fieldName = errorMatch[1]
      const errorMessage = errorMatch[2]
      errors.push({ [fieldName]: decodeURIComponent(errorMessage) })
    } else {
      const genericErrorMatch = key.match(/^(\w+):(.+)$/)
      if (genericErrorMatch) {
        const fieldName = genericErrorMatch[1]
        const errorMessage = genericErrorMatch[2]
        errors.push({ [fieldName]: decodeURIComponent(errorMessage) })
      }
    }
  })

  // Format the output as required
  const output = {
    player: playerData,
    errors: errors,
    access_token,
  }

  return output
}
