import * as React from 'react'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'

import * as Configuration from '../configuration'

import { FacebookOAuthProvider } from './facebook-oauth-provider'
import { GoogleOAuthProvider } from './google-oauth-provider'

export function OAuthproviders(props) {
  const googleClientId = ReactRedux.useSelector(state =>
    Configuration.getGoogleClientId(state.configuration)
  )

  const facebookAppId = ReactRedux.useSelector(state =>
    Configuration.getFacebookAppId(state.configuration)
  )

  const apiUrl = ReactRedux.useSelector(state =>
    Configuration.getApiUrl(state.configuration)
  )
  const redirectUrl = ReactRedux.useSelector(state =>
    Configuration.getOAuthRedirectUrl(state.configuration)
  )

  return (
    <GoogleOAuthProvider
      apiUrl={apiUrl}
      clientId={googleClientId}
      redirectUrl={redirectUrl}
    >
      <FacebookOAuthProvider
        apiUrl={apiUrl}
        appId={facebookAppId}
        redirectUrl={redirectUrl}
      >
        {props.children}
      </FacebookOAuthProvider>
    </GoogleOAuthProvider>
  )
}

OAuthproviders.propTypes = {
  children: PropTypes.node.isRequired,
}

export * from './google-oauth-provider'
export * from './facebook-oauth-provider'
