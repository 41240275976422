/*

THEME DEFINITIONS

Legendz UI is theme-based, which means no values are defined ad-hoc but taken from
theme via context. This allows to treat colors, fonts, shadows, spacings, etc.
as assets that can be replaced at any time without losing visual hierarchy and meaning of the UI.

To achieve that some rules must be followed.

- Theme MUST adhere to “System UI Theme Specification” unless overruled by
  further statements.
- Breakpoints MUST be defined in pixels.
  Reason for that is that relative values will change breakpoints absolute
  values if font size changes, which may lead to issues.
- Colors MUST have semantic names (e.g. `success` is preferred over `green`)
- Colors MUST not have any presentational words in their names (e.g. `green`,
  `light`, etc.)
- Colors values MAY be duplicate if there are two semantically different cases
  that use the same color (e.g. `primary` and `success` might have same value)
- Dimension-like values (font sizes, spacings, shadows) MUST be defined as
  arrays of values in order from smallest to largest

Recommended resources:
- System UI Theme Specification: https://system-ui.com/theme/
- Styled System docs: https://styled-system.com/

*/

import * as EmotionTheming from 'emotion-theming'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Configuration from '../configuration'

import theme from './theme'

export function Alternative(props) {
  return (
    <EmotionTheming.ThemeProvider
      theme={theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          ...theme.colors.__variants.alternative,
        },
      })}
    >
      {props.children}
    </EmotionTheming.ThemeProvider>
  )
}

export function Primary(props) {
  return (
    <EmotionTheming.ThemeProvider
      theme={theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          ...theme.colors.__variants.primary,
        },
      })}
    >
      {props.children}
    </EmotionTheming.ThemeProvider>
  )
}

function ThemeProvider(props) {
  return (
    <EmotionTheming.ThemeProvider theme={theme}>
      <Primary>{props.children}</Primary>
    </EmotionTheming.ThemeProvider>
  )
}

// TODO: remove brand checking
export const Provider = Configuration.withBrand(ThemeProvider)

Alternative.propTypes = {
  brand: PropTypes.string,
  children: PropTypes.node,
}

Primary.propTypes = {
  brand: PropTypes.string,
  children: PropTypes.node,
}

ThemeProvider.propTypes = {
  brand: PropTypes.string,
  children: PropTypes.node,
}
