import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Herz from '@rushplay/herz'
import * as Forms from '@rushplay/forms'
import * as Common from '@rushplay/common'

import { Button } from './button'
import { ButtonLoader } from './button-loader'

function ButtonContent(props) {
  const translate = Herz.I18n.useTranslate(
    () => [`${props.name}.submit.label`],
    [props.name]
  )

  if (props.showSpinner) {
    return (
      <Common.Box maxWidth="30px" maxHeight="30px" m="-2px auto 0px">
        <ButtonLoader />
      </Common.Box>
    )
  }

  if (props.children) {
    return props.children
  }

  if (props.label) {
    return props.label
  }

  return translate(`${props.name}.submit.label`) ?? null
}

ButtonContent.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  label: PropTypes.string,
  showSpinner: PropTypes.bool,
}

export function SubmitButton(props) {
  const form = Forms.useFormContext()
  const errors =
    props.onlyScopes == null
      ? Forms.getFormErrors(form.state)
      : R.pick(props.onlyScopes, Forms.getFormErrors(form.state))

  const disabled = props.disabled ?? (R.not(R.isEmpty(errors)) || props.loading)
  const showSpinner = props.loading && props.spinner

  return (
    <Button
      style={{
        ...props.customStyle,
      }}
      data-testid={`${form.name}-submit`}
      disabled={disabled}
      fontSize={props.fontSize}
      stretch={props.stretch}
      type="submit"
      variant={props.variant}
    >
      <ButtonContent
        name={form.name}
        label={props.label}
        showSpinner={showSpinner}
      >
        {props.children}
      </ButtonContent>
    </Button>
  )
}

SubmitButton.defaultProps = {
  fontSize: [2, 3],
  variant: 'primary',
  spinner: false,
  customStyle: {},
}

SubmitButton.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  label: PropTypes.string,
  loading: PropTypes.bool,
  stretch: PropTypes.bool,
  spinner: PropTypes.bool,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'primary-outlined',
    'secondary-outlined',
  ]),
  onlyScopes: PropTypes.arrayOf(PropTypes.string),
  customStyle: PropTypes.object,
}
