export const tileSizeLimit = {
  large: 9,
  medium: 10,
  small: 6,
}

export function calculateGamesToRender(games, tileSize, isMobile, page) {
  let limit = 0

  if (!games) {
    return []
  }

  if (page === 'discover') {
    if (isMobile) {
      limit = 6
      return games?.slice(0, limit)
    }

    if (games.length % 2 !== 0 && tileSize !== 'large') {
      //display always even number of games
      limit = Number(games.length) - 1
      return games?.slice(0, limit)
    }

    limit = tileSizeLimit[tileSize] || games?.length || 6
    return games?.slice(0, limit)
  }

  if (games.length <= 4) {
    limit = 4
    return games?.slice(0, limit)
  }

  return games
}
