export function message_broadcast(name, message) {
  if (!name) {
    throw new Error('Event name was not specified.')
  }

  localStorage.setItem(name, JSON.stringify(message))
  localStorage.removeItem(name)
}

export function message_receive(name, event, callback) {
  if (!name) {
    throw new Error('Event name was not specified.')
  }

  if (event?.key != name) {
    return
  } // ignore other keys

  const message = JSON.parse(event.newValue)

  if (!message) {
    return
  } // ignore empty msg or msg reset

  return callback(message)
}
