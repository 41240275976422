import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Forms from '@rushplay/forms'
import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import { lazy as loadable } from '@loadable/component'

import { Checkbox } from './checkbox'
import { getFieldNormalizer } from './get-field-normalizer'
import { getFieldParser } from './get-field-parser'

const FieldResponse = loadable(() => import('./field-response'))

export function CookieConsentCheckboxGroup(props) {
  const translate = Herz.I18n.useTranslate()
  const field = Forms.useField(props.scope, {
    initialValue: props.initialValue,
    normalize: getFieldNormalizer(props.format, props.normalize),
    parse: getFieldParser(props.format, props.parse),
  })

  function handleChange(key, value) {
    // checkbox was false
    if (value && R.includes(key, field.value)) {
      return field.onChangeValue(R.without(key, field.value))
    }
    // checkbox was true
    return field.onChangeValue(R.append(key, field.value))
  }

  if (field.status === 'ABSENT') {
    return null
  }

  return (
    <Common.Box display="flex" flexDirection="column" px="2" py="0">
      <Common.Box pb={1}>
        <Common.Box fontWeight="bold" fontSize={[1, 2]} pb={1}>
          {translate('consent.consent.base.title')}
        </Common.Box>
        <Common.Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Common.Box fontSize={[0, 1]} pr={3}>
            {translate('consent.consent.base.description')}
          </Common.Box>
          <Common.Box>
            <Checkbox
              name="base"
              disabled
              checked={R.includes('base', field.value)}
              value="base"
              onChange={value => handleChange('base', value)}
            />
          </Common.Box>
        </Common.Box>
      </Common.Box>

      <Common.Box pb={1}>
        <Common.Box fontWeight="bold" fontSize={[1, 2]} pb={1}>
          {translate('consent.consent.ads.title')}
        </Common.Box>
        <Common.Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Common.Box fontSize={[0, 1]} pr={3}>
            {translate('consent.consent.ads.description')}
          </Common.Box>
          <Common.Box>
            <Checkbox
              name="ads"
              checked={R.includes('ads', field.value)}
              value="ads"
              onChange={value => handleChange('ads', value)}
            />
          </Common.Box>
        </Common.Box>
      </Common.Box>
      <FieldResponse scope={props.scope} />
    </Common.Box>
  )
}

CookieConsentCheckboxGroup.propTypes = {
  initialValue: PropTypes.array,
  format: PropTypes.string,
  label: PropTypes.string,
  normalize: PropTypes.func,
  options: PropTypes.array,
  parse: PropTypes.func,
  scope: PropTypes.string,
  value: PropTypes.string,
}
