import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as locks from '@rushplay/compliance/locks'
import * as offers from '@rushplay/offers'
import * as Triggers from '@rushplay/triggers'
import * as Herz from '@rushplay/herz'

import * as Analytics from './analytics'
import * as CombinedSelectors from './combined-selectors'
import * as player from './player'
import * as promotions from './promotions'
import * as Inventory from './inventory'
import * as Constants from './constants'
import * as Cookies from './cookies-module'
import { getDomain } from './get-domain'

/**
 * Maintain player-dependent state up-to-date
 */
export function useSessionListener() {
  // cookieAuthToken and sessionToken should be the same tokens
  // we need both until we move all requests to be done via graphql
  const [cookieAuthToken, setCookieAuthToken] = Cookies.useCookie(
    Constants.CookieKeys.TOKEN
  )
  const [, setSportsbookAuthToken] = Cookies.useCookie(
    Constants.CookieKeys.SPORTSBOOK_TOKEN
  )
  const { token: sessionToken, invalidate, destroy } = Herz.Auth.useSession()
  const dispatch = useDispatch()
  const affiliateId = useSelector(state => player.getAffiliateId(state.player))
  const isPhoneVerificationRequired = CombinedSelectors.useIsPhoneVerificationRequired()

  // Fetch data on token change
  React.useEffect(() => {
    if (sessionToken && !isPhoneVerificationRequired) {
      dispatch([
        player.fetchPlayerInfo(),
        Analytics.analyticsRegistration(),
        locks.fetch(),
        offers.fetchDepositOffers({ affiliateId }),
        promotions.fetch(),
        Inventory.fetch(),
        Triggers.fetch(),
      ])
    }
  }, [sessionToken, isPhoneVerificationRequired])

  React.useEffect(() => {
    function handleKeepAlive() {
      if (!document.hidden && cookieAuthToken && !sessionToken) {
        destroy()
          .then(() => {
            invalidate()
            setCookieAuthToken(null)
            setSportsbookAuthToken(null, { path: '/', domain: getDomain() })
            // @TODO replace invalidate with cache query updates
            // invalidate() will trigger additional request to refetch data
            // Instead we can make manual cache query update inside
            // cache-exchange. Although this needs more investigation, as I
            // couldn't see these updates, unless console.log-ged explicitly.
            // updates: {
            //   Mutation: {
            //     deleteSession(result, _args, cache) {
            //       cache.updateQuery(
            //         {
            //           query: `
            //             query getPlayerSession {
            //               player {
            //                 session {
            //                   __typename
            //                   token
            //                   authenticated
            //                   createdAt
            //                 }
            //               }
            //             }
            //           `,
            //         },
            //         () => ({
            //           player: {
            //             session: { ...result.deleteSession },
            //           },
            //         })
            //       )
            //     },
            //   },
            // },
          })
          .catch(error => {
            // eslint-disable-next-line no-console
            console.error(error)
          })
      }
    }

    if (cookieAuthToken) {
      // Setup listener for tab-change and pauses. (example: closing lid of laptop)
      document.addEventListener('visibilitychange', handleKeepAlive)

      return () => {
        document.removeEventListener('visibilitychange', handleKeepAlive)
      }
    }
  }, [sessionToken, cookieAuthToken])
}
