export function getDomain() {
  if (process.browser) {
    const hostname = window.location.hostname
    if (hostname.includes('legendzstage.com')) {
      return '.legendzstage.com'
    }
    if (hostname.includes('legendz.com')) {
      return '.legendz.com'
    }
  }
  return 'localhost'
}
